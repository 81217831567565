<template>
	<div class="visitor-form2 ">
        <div class="pc-box">
            <div class="title">
                <span class="text1" :style="{color:titleColor}">{{title}}</span>
                <span class="text2">{{desc}}</span>
            </div>
            <div class="form ">
                <el-form
                    :inline="true" 
                    ref="ruleFormRef"
                    :model="visitorInfo"
                    :rules="rules"
                    class="cus-form"
                >
                    <el-form-item   >
                        <el-input
                            class="input phone"
                            placeholder="请输入您的称呼"
                            v-model="visitorInfo.name"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item   prop="mobile">
                        <el-input
                            class="input phone"
                            placeholder="请输入您的电话"
                            v-model="visitorInfo.mobile"
                            inputmode="numeric"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>
                <div @click="submitForm" class="btn f-x-c-c">立即提交</div>
            </div>
        </div>
        <div 
            class="mob-box f-y-s-c"
        >
            <div 
                class="form-top f-y-c-c"
            >
                <span class="title">{{ title }}</span>
                <span class="desc">{{ desc }}</span>
            </div>
            <div class="form-box f-y-c-c">
                
                <div class="box f-y-s-c">


                    <el-form
                        :inline="true" 
                        ref="ruleFormRef"
                        :model="visitorInfo"
                        :rules="rules"
                        class="cus-form"
                    >
                        <el-form-item  v-if="showName" >
                            <el-input
                                class="input"
                                placeholder="请输入您的称呼"
                                v-model="visitorInfo.name"
                            >
                            </el-input>
                        </el-form-item>
                                
                        <el-form-item v-if="showMobile"  prop="mobile">
                            <el-input
                                class="input"
                                placeholder="请输入您的电话"
                                v-model="visitorInfo.mobile"
                                inputmode="numeric"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item v-if="showArea"  prop="houseArea">
                            <el-input
                                class="input"
                                placeholder="请输入您家房屋面积"
                                v-model="visitorInfo.houseArea"
                                inputmode="numeric"
                            >
                                <div slot="suffix" class="m2">m²</div>
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="showHouseType"  >
                            <el-input
                                
                                class="input"
                                placeholder="请输入您的房屋户型"
                                v-model="visitorInfo.houseType"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item v-if="showCity"  >
                            <el-cascader
                                class="input city"
                                placeholder="请选择您所在城市门店"
                                size="default"
                                :options="cityStoreList"
                                v-model="city"
                            >
                            </el-cascader>
                        </el-form-item>
                    </el-form>
                </div>
                <div @click="submitForm" class="btn f-x-c-c">立即留言</div>
                <!-- <span class="tip">为了你的权益，你的隐私将被严格保密</span> -->
            </div>
        </div>
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
	</div>
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {useCityInfo} from '@/utils/frequent.js'
export default {
	props: {
		title: {
			type: String,
			default: '获取装修方案',
		},
        titleColor: {
			type: String,
			default: '#120523',
		},
        showName:{
            type:Boolean,
            default:true,
        },
        showMobile:{
            type:Boolean,
            default:true,
        },
        showArea:{
            type:Boolean,
            default:true,
        },
        showHouseType:{
            type:Boolean,
            default:true,
        },
        showCity:{
            type:Boolean,
            default:true,
        },
		desc: {
			type: String,
			default: '一站式装修拎包即入住服务',
		},
        adUnitCode: {
			type: String,
            default:"XSdw"
		},
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
            city:[],
            cityStoreList:[],
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: '', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'blur' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            timer:""
		}
	},
	async mounted() {
		this.setAccessInfo()
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
	},
	methods: {
		setAccessInfo() {
            this.visitorInfo.adUnitCode = this.adUnitCode

			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
		// async submit() {
		// 	try {
		// 		if (
		// 			!this.visitorInfo.mobile.match(
		// 				/^[1][3,4,5,6.7,8,9][0-9]{9}$/
		// 			) || !this.visitorInfo.mobile
		// 		) {
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message: '请输入正确的手机号',
		// 				type: 'warning',
		// 			})
		// 			// this.visitorInfo.mobile = ''
		// 		} else {
        //             if(this.city.length){
		// 			    this.visitorInfo.cityCode = this.city[0]
		// 			    this.visitorInfo.salesRoomNo = this.city[1]
        //             }

		// 			const res = await postmobile(this.visitorInfo)
        //             this.city=[]
		// 			this.visitorInfo.mobile = ''
		// 			this.visitorInfo.provinceCode = ''
		// 			this.visitorInfo.cityCode = ''
		// 			this.visitorInfo.houseArea = ''
		// 			this.visitorInfo.houseType = ''
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message:
		// 					'提交成功！稍后家装顾问与您联系，请注意021开头电话',
		// 				duration: 2000,
		// 				offset: 120,
		// 			})
		// 		}
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// },
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .visitor-form2 {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
        border-radius: 9px;
        box-sizing: border-box;
        .pc-box{
            display: none;
        }
        .mob-box {
            width: 100%;
            // height: 90px;
            background: #ffffff;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 25px 15px;
            box-sizing: border-box;
            .form-top{
                width: 100%;
                position: relative;
                .title{
                    
                    font-weight: bold;
                    font-size: 22px;
                    color: #120523;
                    line-height: 22px;
                }
                .desc{
                    margin-top: 8px;
                    
                    font-weight: 400;
                    font-size: 15px;
                    color: #444444;
                    line-height: 15px;
                }
            }
            .form-box{
                width: 100%;
                // height: 200px;
                border-radius: 4px;
                opacity: 0.96;
                background-repeat: no-repeat;
                box-sizing: border-box;
                margin-top: 16px;
            
                .box{
                    width: 100%;
                    .cus-form{
                        .el-form-item{
                            width: 100%;
                            /deep/.el-form-item__content{
                                width: 100%;
                            }
                            
                        }
                    }
                    .input{
                        width: 100%;
                        margin-bottom: 10px;
                        /deep/ .el-input{
                            width: 100%;
                            height:40px;
                            
                            .el-input__inner{
                                width: 100%;
                                height:40px;
                            }
                        }
                        /deep/ .el-input__inner{
                            width: 100%;
                            height:40px;
                            font-size: 12px !important;
                        }
                        
                        
                        /deep/.el-input__suffix{
                            right: 10px;
                            .m2{
                                height:40px;
                                line-height:40px;
                                font-size: 12px;
                            }
                        }
                        /deep/.el-icon-arrow-down{
                            font-size: 12px !important;

                        }
                    }
                    .el-cascader{
                        /deep/.el-input__suffix{
                            right: 13px !important;
                        }
                    }
                    
                }
                .btn{
                    // width: 410px;
                    width: 100%;
                    height:40px;
                    background: #F6900C;
                    border-radius: 2px;
                    
                    font-weight: bold;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 16px;
                    cursor: pointer;
                }
            }
            
        }
       
       
    }
}
@media screen and (min-width: 900px) {
	.visitor-form2 {
        width: 100%;
        height: 206px;
        .mob-box{
            display: none;
        }
        .pc-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
            border-radius: 18px;
            border: 1px solid #F1F1F1;
            padding: 53px 32px;
            box-sizing: border-box;
            .title{
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: flex-start;
                .text1{
                    
                    font-weight: bold;
                    font-size: 54px;
                    color: #120523;
                    line-height: 54px;
                }
                .text2{
                    
                    font-weight: 400;
                    font-size: 30px;
                    color: #444444;
                    line-height: 30px;
                    margin-top: 16px;
                }
            }
            .form{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                .input{
                    width: 360px;
                    margin-right: 30px;
                    /deep/ .el-input{
                        width: 360px;
                        height: 100px;

                        .el-input__inner{
                            width: 100%;
                            height: 100px;
                        }
                    }
                    /deep/ .el-input__inner{
                        width: 100%;
                        height: 100px;
                        font-size: 24px !important;
                    }
                }
                .btn{
                    width: 313px;
                    height: 101px;
                    background: #FD8B2F;
                    border-radius: 4px;
                    border: 1px solid #E5E5E5;
                    
                    font-weight: 400;
                    font-size: 36px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
       
        
    }
}
</style>
