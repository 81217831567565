<template>
	<div class="visitor-form f-y-c-c">
		<span class="title">{{ title }}</span>
		<span class="desc">{{ desc }}</span>
		<div class="box f-y-s-c">
            <el-form
                :inline="true" 
                ref="ruleFormRef"
                :model="visitorInfo"
                :rules="rules"
                class="cus-form"
            >
                <el-form-item v-if="showAddress"  >
                    <div  class="list-item ">
                        <div class="left-label f-x-c-c">
                            所在城市：
                        </div>
                        <el-cascader
                            placeholder=""
                            class="input city"
                            size="default"
                            :options="cityStoreList"
                            v-model="city"
                        >
                        </el-cascader>
                    </div>
                </el-form-item>

                <el-form-item   v-if="showName">
                    <div  class="list-item ">
                        <div class="left-label f-x-c-c">
                            您的称呼：
                        </div>
                        <el-input
                            
                            class="input"
                            v-model="visitorInfo.name"
                        >
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item  v-if="showMob" prop="mobile">
                    <div  class="list-item ">
                        <div class="left-label f-x-c-c">
                            您的电话：
                        </div>
                        <el-input
                            class="input phone"
                            v-model="visitorInfo.mobile"
                            inputmode="numeric"
                        >
                            <div slot="prefix" class="req">*</div>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item v-if="showArea"  prop="houseArea">
                    <div  class="list-item ">
                        <div class="left-label f-x-c-c">
                            房屋面积：
                        </div>
                        <el-input
                            class="input"
                            v-model="visitorInfo.houseArea"
                            inputmode="numeric"
                        >
                            <div slot="suffix" class="m2">m²</div>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item  v-if="showHouseType" >
                    <div  class="list-item ">
                        <div class="left-label f-x-c-c">
                            房屋户型：
                        </div>
                        <el-select v-model="visitorInfo.houseType" placeholder="房屋户型：" >
                            <el-option
                                v-for="item in houStyleDict"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
            </el-form>


            <!-- <div v-if="showAddress" class="list-item ">
                <div class="left-label f-x-c-c">
                    所在城市：
                </div>
                <el-cascader
                    placeholder=""
                    class="input city"
                    size="default"
                    :options="cityStoreList"
                    v-model="city"
                >
                </el-cascader>
            </div>
            <div v-if="showName" class="list-item ">
                <div class="left-label f-x-c-c">
                    您的称呼：
                </div>
                <el-input
                    
                    class="input"
                    v-model="visitorInfo.name"
                >
                </el-input>
            </div>
            <div v-if="showName" class="list-item ">
                <div class="left-label f-x-c-c">
                    您的电话：
                </div>
                <el-input
                    class="input phone"
                    v-model="visitorInfo.mobile"
                    inputmode="numeric"
                >
                    <div slot="prefix" class="req">*</div>
                </el-input>
            </div>

            <div v-if="showArea" class="list-item ">
                <div class="left-label f-x-c-c">
                    房屋面积：
                </div>
                <el-input
                    class="input"
                    v-model="visitorInfo.houseArea"
                    inputmode="numeric"
                >
                    <div slot="suffix" class="m2">m²</div>
                </el-input>
            </div>
            <div v-if="showHouseType" class="list-item ">
                <div class="left-label f-x-c-c">
                    房屋户型：
                </div>
                <el-select v-model="visitorInfo.houseType" placeholder="房屋户型：" >
                    <el-option
                        v-for="item in houStyleDict"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
            
		</div>
        <div @click="submitForm" class="btn f-x-c-c">立即留言</div>
        <!-- <span class="tip">为了你的权益，你的隐私将被严格保密</span> -->
        <MessageForm ref="refMessage" :list="errList"></MessageForm>
    </div>
</template>
<script>
import { postmobile } from '@/api/views/name.js'
import {
	regionData,
	provinceAndCityData,
	codeToText,
} from 'element-china-area-data'
import {useCityInfo,useHousetypeDict} from '@/utils/frequent.js'
export default {
	props: {
		title: {
			type: String,
			default: '免费装修方案',
		},
		desc: {
			type: String,
			default: '设计+户型+施工+人工+售后',
		},
        showName:{
            type:Boolean,
            default:true
        },
        showMob:{
            type:Boolean,
            default:true
        },
        showArea:{
            type:Boolean,
            default:true
        },
        showAddress:{
            type:Boolean,
            default:true
        },
        showHouseType:{
            type:Boolean,
            default:true
        },
        adUnitCode: {
			type: String,
            default:"XSdw"
		},
	},
	data() {
        var validatePhone = (rule, value, callback) => {
            if (!value.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)) {
                callback('请输入正确的电话号码');
            }else{
                callback();
            }
            
        };
		return {
            city:[],
            provinceAndCityData,
            cityStoreList:[],
            houStyleDict:[],
			visitorInfo: {
				houseArea: '',
				mobile: '', //电话
				name: '',
				bizSource: 'pc',
				interviewId: '', // 访次id
				visitorId: '', //访客id
				mediaName: '', // 媒体名称
				channelCode: 'website', // 渠道代码
				mediaCode: '', //媒体来源代码
				activityCode: '', // 营销活动代码
				projectName: '', // 营销计划名称
				projectCode: '', // 营销计划也就是广告计划的代码
				adUnitCode: '', // 对应当前页面的广告单元代码
				reqUrl: '', // 提交信息的时候对应当前页面的url
			},
            rules:{
                // houseArea: [
                //     { required: true, message: ' ', trigger: 'change' },
                // ],
                mobile: [
                    { required: true, message: ' ', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' },
                ],
            },
            errList:[],
            timer:""
		}
	},
	async mounted() {
		this.setAccessInfo()
        this.houStyleDict = await useHousetypeDict()
        this.cityStoreList = await useCityInfo()
        if(this.curCityInfo.value!='00') this.setCity()
	},
	methods: {
		setAccessInfo() {
            this.visitorInfo.adUnitCode = this.adUnitCode
			this.visitorInfo.interviewId = this.$Cookie.get('MJ0INTERVIEWID')
			this.visitorInfo.visitorId = this.$Cookie.get('MJ0VISITORID')
			this.visitorInfo.reqUrl = window.location.href

            this.visitorInfo.activityCode = this.$Cookie.get('activityCode') 
            this.visitorInfo.projectCode =  this.$Cookie.get('projectCode')
            this.visitorInfo.mediaCode =  this.$Cookie.get('mediaCode')
            this.visitorInfo.projectName =  this.$Cookie.get('projectName')
            this.visitorInfo.mediaName = this.$Cookie.get('mediaName'  )
		},
        async submitForm() {
			try {
                this.$refs.ruleFormRef.validate(async(valid,obj)=>{
                    console.log("aa",obj);
                    if(valid){
                        if(this.city.length){
                            this.visitorInfo.cityCode = this.city[0]
                            this.visitorInfo.salesRoomNo = this.city[1]
                        }

                        const res = await postmobile(this.visitorInfo)
                        this.$refs.refMessage.showDialog('sucess')
                        this.city=[]
                        this.visitorInfo.mobile = ''
                        this.visitorInfo.provinceCode = ''
                        this.visitorInfo.cityCode = ''
                        this.visitorInfo.houseArea = ''
                        this.visitorInfo.name = ''
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        this.$refs.ruleFormRef.clearValidate()

                    }else{
                        this.$refs.refMessage.showDialog('error')
                        const res = []
                        for(const item in obj){
                            if(item=='houseArea') res.push('请填写您家房屋面积')
                            if(item=='mobile'){
                                if(obj[item][0].message!=' ')  res.push(obj[item][0].message)
                                else res.push('请填写您的电话号码')
                            } 
                        }
                        this.errList = res
                        clearTimeout(this.timer)
                        this.timer = setTimeout(()=>{
                            this.$refs.refMessage.closeDialog()
                        },8000)
                        return false;
                    }
                })
			} catch (err) {
				console.log(err)
			}
		},
		// async submit() {
		// 	try {
		// 		if (
		// 			!this.visitorInfo.mobile.match(
		// 				/^[1][3,4,5,6.7,8,9][0-9]{9}$/
		// 			)
		// 		) {
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message: '请输入正确的手机号',
		// 				type: 'warning',
		// 			})
		// 			// this.visitorInfo.mobile = ''
		// 		} else {
        //             if(this.city.length){
		// 			    this.visitorInfo.cityCode = this.city[0]
        //                 this.visitorInfo.salesRoomNo = this.city[1]
        //             }

		// 			const res = await postmobile(this.visitorInfo)
        //             this.city=[]
		// 			this.visitorInfo.mobile = ''
		// 			this.visitorInfo.provinceCode = ''
		// 			this.visitorInfo.cityCode = ''
		// 			this.visitorInfo.houseArea = ''
        //             this.visitorInfo.name = ''
		// 			this.$message.closeAll()
		// 			this.$message({
		// 				message:
		// 					'提交成功！稍后家装顾问与您联系，请注意021开头电话',
		// 				duration: 2000,
		// 				offset: 120,
		// 			})
		// 		}
		// 	} catch (err) {
		// 		console.log(err)
		// 	}
		// },
	},
    beforeDestroy(){
        clearTimeout(this.timer)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .visitor-form {
		width: 100%;
		// height: 300px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
        border-radius: 5px;
		overflow: hidden;
        padding: 30px 20px;
        box-sizing: border-box;
		.title{
            
            font-weight: bold;
            font-size: 18px;
            color: #140626;
            line-height: 18px; 
        }
        .desc{
            
            font-weight: 400;
            font-size: 16px;
            color: #140626;
            margin-top: 5px;
        }
        .box{
            margin-top: 10px;
            width: 100%;
            .list-item{
                width: 100%;
                position: relative;
                .left-label{
                    position: absolute;
                    z-index: 9;
                    height: 30px;
                    width: 80px;
                    padding-left: 5px ;
                    box-sizing: border-box;
                    left: 5px;
                    top: 5px;
                    background: rgb(255, 255, 255);
                    
                    font-weight: 300;
                    font-size: 15px ;
                    color: #444444 ;
                }
                .phone {
                    /deep/.el-input__inner {
                        padding-left: 15px !important;
                    }
                }
                .input {
                    width: 100%;
                    margin-bottom: 10px;
                    .req {
                        color: #e30c0c;
                        height: 40px;
                        line-height: 40px;
                    }
                    /deep/.el-input__inner {
                        width: 100%;
                        height: 40px;
                        padding-left: 85px !important;
                        
                        font-weight: 300;
                        font-size: 15px !important;
                        color: #444444 !important;
                        line-height: 40px !important;
                    }
                    /deep/.el-input__suffix {
                        right: 10px;
                        .m2 {
                            
                            font-weight: 300;
                            color: #666666;
                            line-height: 15px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 15px;
                        }
                    }
                    /deep/.el-icon-arrow-down {
                        font-size: 15px !important;
                    }
                }
                .el-cascader {
                    /deep/.el-input__suffix {
                        right: 10px !important;
                    }
                }
            }
            
            
        }
        .btn{
            width: 100%;
            height: 40px;
            background: #753CBE;
            border-radius: 2px;
            
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 40px;
            cursor: pointer;
        }
        // .tip{
        //     
        //     font-weight: 300;
        //     font-size: 10px;
        //     color: #140626;
        //     line-height: 10px;
        //     margin-top: 10px;
        // }
	}
}
@media screen and (min-width: 900px) {
	.visitor-form {
		width: 100%;
		height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
        border-radius: 10px;
		overflow: hidden;
        padding: 48px 30px 40px 30px;
        box-sizing: border-box;
		.title{
            
            font-weight: bold;
            font-size: 44px;
            color: #18062F;
            line-height: 30px;
        }
        .desc{
            margin-top: 12px;
            
            font-weight: 400;
            font-size: 30px;
            color: #444444;
            line-height: 30px;
        }
        .box{
            margin-top: 40px;
            width: 100%;
            .list-item{
                width: 100%;
                position: relative;
                .left-label{
                    position: absolute;
                    z-index: 9;
                    height: 80px;
                    width: 160px;
                    padding-left: 10px ;
                    box-sizing: border-box;
                    left: 5px;
                    top: 5px;
                    background: rgb(255, 255, 255);
                    
                    font-weight: 300;
                    font-size: 30px ;
                    color: #444444 ;
                }
                .phone {
                    /deep/.el-input__inner {
                        padding-left: 15px !important;
                    }
                }
                .input {
                    width: 100%;
                    margin-bottom: 17px;
                    .req {
                        color: #e30c0c;
                        height: 90px;
                        line-height: 75px;
                    }
                    /deep/.el-input__inner {
                        width: 100%;
                        height: 90px;
                        padding-left: 165px !important;
                        
                        font-weight: 300;
                        font-size: 30px !important;
                        color: #444444 !important;
                        line-height: 90px !important;
                    }
                    /deep/.el-input__suffix {
                        right: 20px;
                        .m2 {
                            
                            font-weight: 300;
                            color: #666666;
                            line-height: 30px;
                            height: 90px;
                            line-height: 90px;
                            font-size: 24px;
                        }
                    }
                    /deep/.el-icon-arrow-down {
                        font-size: 24px !important;
                    }
                }
                .el-cascader {
                    /deep/.el-input__suffix {
                        right: 27px !important;
                    }
                }
            }
            
            
        }
        .btn {
            width: 100%;
            height: 90px;
            background: #fd8b2f;
            border-radius: 4px;
            
            font-weight: bold;
            font-size: 30px;
            color: #ffffff;
            line-height: 30px;
            cursor: pointer;
            margin-top: 12px;
        }
	}
}
</style>
