<template>
	<div class="whole" v-title="'整体家装——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img
				class="bran"
				src="../image/decoration/whole_header_pc.jpg"
				alt=""
			/>
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/decoration/whole_header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content f-y-s-c">
			<div class="box1 ">
                <div class="form-box">
                    <VisitorForm4 
                        :showAddress="false"
                        :showHouseType="false"
                        title="整体家装方案" 
                        desc="免费快速获取整体家装方案"
                        adUnitCode="uDB2"
                        >
                    </VisitorForm4>
                </div>
				<img
					src="../image/decoration/whole_box1.png"
					alt=""
					class="img1"
				/>
			</div>
			<div class="box2 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">一站式全屋整装</span>
					<span class="text2"
						>灵感源于都是新生活，产品研发团队精心调研都是人群的居家生活习惯，
					</span>
					<span class="text3">致力于打造人性化的现代家居</span>

                    <p class="text4">
                        灵感源于都是新生活，产品研发团队精心调研都是人群的居家生活习惯，致力于打造人性化的现代家居
                    </p>
				</div>
				
                <div class="box2-content ">
					<div class="box-left">
						<div
							v-for="(item, index) in list1"
							:key="index"
							class="list-item"
							@mouseenter="curAddressVal = index"
						>
							<div v-if="curAddressVal != index" class="title">
								{{ item.title }}
							</div>
							<div v-else class="desc f-y-s-c">
								<div class="top f-x-c-c">
									<div class="lin"></div>
									<span class="text3"
										>&nbsp;{{ item.title }}&nbsp;</span
									>
									<div class="lin"></div>
								</div>
								<div class="bottom">
									<span
										v-for="(it, ind) in item.childs"
										:key="ind"
										class="text1"
										>{{ it }}</span
									>
                                    <div class="cus-line1"></div>
								    <div class="cus-line2"></div>
								</div>
								
							</div>
						</div>
					</div>
					<div class="box-right">
						<div
							class="mask f-x-c-c"
							:class="'address' + curAddressVal"
						>
							<div class="mask2 f-x-c-c">
								<div class="maks3"></div>
							</div>
						</div>
						<img
							class="img3"
							src="../image/decoration/whole_box2_bg.png"
							alt=""
						/>
					</div>
				</div>
                <div class="box2-content1 ">
					<div class="box-left f-x-b-c">
						<div
							v-for="(item, index) in list1"
							:key="index"
							class="list-item f-x-c-c"
							:class="curAddressVal == index ? 'list-active':''"
							@mouseenter="curAddressVal = index"
						>
							<div  class="title ">
								{{ item.title }}
							</div>
							
						</div>
                        
					</div>
                    <div class="desc f-y-s-c">
                        <div class="top f-x-c-c">
                            <div class="lin"></div>
                            <span class="text3"
                                >&nbsp;{{ list1[curAddressVal].title }}&nbsp;</span
                            >
                            <div class="lin"></div>
                        </div>
                        <div class="bottom">
                            <span
                                v-for="(it, ind) in list1[curAddressVal].childs"
                                :key="ind"
                                class="text1"
                                >{{ it }}</span
                            >
                            <div class="cus-line1"></div>
                            <div class="cus-line2"></div>
                        </div>
                        
                    </div>
					<div class="box-right">
						<div
							class="mask f-x-c-c"
							:class="'address' + curAddressVal"
						>
							<div class="mask2 f-x-c-c">
								<div class="maks3"></div>
							</div>
						</div>
						<img
							class="img3"
							src="../image/decoration/whole_box2_bg.png"
							alt=""
						/>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">全球品牌建材</span>
					<span class="text2"
						>专业材料甄选团队，严格筛选更安全环保</span
					>
				</div>
				<div class="box3-content f-y-c-c">
					<div class="tab-list f-x-b-c">
						<div
							v-for="(item, index) in tabList"
							:key="index"
							class="list-item f-x-c-c"
							:class="curTab == index ? 'tab-active' : ''"
							@click="cahngeTabs(index)"
						>
							<img class="img3" :src="item.icon" alt="" />
						</div>
					</div>
					<div class="banner">
						<img class="img4" :src="tabList[curTab].url" alt="" />
						<div class="info f-y-c-s">
							<span class="text3">{{
								tabList[curTab].title
							}}</span>
							<span class="text4">{{
								tabList[curTab].form
							}}</span>
							<p class="text5">
								{{ tabList[curTab].desc }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="box4">
				<div class="header f-y-c-c">
					<span class="text1">整装创新工艺</span>
					<span class="text2"
						>每一次创新，都是为了让你住的更好，让家变得更美，让理想中的生活和你靠的更近</span
					>
				</div>
				<div class="box4-content ">
					<div class="list">
						<div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_1.jpg" alt="" class="img">
                            <span class="text3">橫无声工艺</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_2.jpg" alt="" class="img">
                            <span class="text3">45°加固保护</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_3.jpg" alt="" class="img">
                            <span class="text3">月亮弯工艺</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_4.jpg" alt="" class="img">
                            <span class="text3">双排连接工艺</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_5.jpg" alt="" class="img">
                            <span class="text3">1:1数字放样</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_6.jpg" alt="" class="img">
                            <span class="text3">智能一键开关</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_7.jpg" alt="" class="img">
                            <span class="text3">抗干扰工艺</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_8.jpg" alt="" class="img">
                            <span class="text3">双重防臭工艺</span>
                        </div>
                        <div class="list-item f-y-c-c">
                            <img src="../image/decoration/whole_box4_9.jpg" alt="" class="img">
                            <span class="text3">零开裂挂网工艺</span>
                        </div>
					</div>
				</div>
			</div>
            <div class="box5 f-y-s-c">
                <div class="pc-box">
                    <div class="top-box f-x-c-c">
                        <VisitorForm2
                            title="获取装修方案"
                            desc="一站式装修拎包即入住服务"
                            :showArea="false"
                            :showHouseType="false"
                            :showCity="false"
                            adUnitCode="uDB2"
                        ></VisitorForm2>
                    </div>
                    <div class="bottom f-y-c-c">
                        <div class="header f-y-c-c">
                            <span class="text1">科学合理化整装工期</span>
                            <span class="text2">硬核标准，一尺一寸一厘必精算测量，每个交付节点的质量验收均严格把控</span>
                        </div>
                        <div class="img-box">
                            <img class="img" src="../image/build/innovate_box2_bg.jpg" alt="">
                        </div>
                    </div>
                </div>
                <div class="mob-box f-y-s-c">
                    <div class="top-box f-x-c-c">
                        <VisitorForm2
                            title="获取装修方案"
                            desc="一站式装修拎包即入住服务"
                            class="form"
                            :showArea="false"
                            :showHouseType="false"
                            :showCity="false"
                            adUnitCode="uDB2"
                        ></VisitorForm2>
                    </div>
                    <div class="bottom f-y-c-c">
                        <div class="header f-y-c-c">
                            <span class="text1">科学合理化整装工期</span>
                            <span class="text2">硬核标准，一尺一寸一厘必精算测量，每个交付节点的质量验收均严格把控</span>
                        </div>
                        <div class="list f-y-c-e">
                            <div class="list-item item1 f-x-s-c">
                                <span class="title">工序</span>
                                <div class="line"></div>
                                <span class="text3">固定节拍，流水施工</span>
                            </div>
                            <div class="list-item item2 f-x-s-c">
                                <span class="title">工艺</span>
                                <div class="line"></div>
                                <span class="text3">高标准工艺，匠心品质</span>
                            </div>
                            <div class="list-item item3 f-x-s-c">
                                <span class="title">工法</span>
                                <div class="line"></div>
                                <span class="text3">严苛淬炼，层层把关，塑造卓越品质</span>
                            </div>
                            <div class="list-item item4 f-x-s-c">
                                <span class="title">工料</span>
                                <div class="line"></div>
                                <span class="text3">入仓+出仓双重检验，质量无忧</span>
                            </div>
                            <div class="list-item item5 f-x-s-c">
                                <span class="title">工检</span>
                                <div class="line"></div>
                                <span class="text3">一次校验合格</span>
                            </div>
                            <div class="list-item item6 f-x-s-c">
                                <span class="title">工保</span>
                                <div class="line"></div>
                                <span class="text3">成品专业保护，让新家从里美到外</span>
                            </div>
                        </div>
                        <div class="img-box f-x-c-c">
                            <div class="btn">六工合一</div>
                        </div>
                    </div>
                </div>
				
			</div> 
		</div>
        <Flow></Flow>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Flow from '../components/Flow.vue'
import VisitorForm2 from '../components/VisitorForm2.vue'
import VisitorForm4 from '../components/VisitorForm4.vue'
import { postmobile } from '@/api/views/name.js'
export default {
	components: {
		NavBar,
		Footer,
        Flow,
        VisitorForm2,
        VisitorForm4
	},
	created() {},
	mounted() {},
	data() {
		return {
			curAddressVal: 0,
			list1: [
				{
					title: '客餐厅',
					childs: [
						'茶几',
						'电视柜',
						'主卧灯',
						'餐厅灯',
						'地板',
						'衣柜',
						'客厅灯',
						'石膏线',
						'入户门套',
						'窗台板',
						'客餐厅吊顶',
						'筒灯+灯带',
						'餐椅',
					],
					address: [],
				},
				{
					title: '主卧',
					childs: [
						'地板',
						'床头柜',
						'石膏线',
						'踢脚线',
						'衣柜',
						'开关插座',
						'主卧灯',
						'木门',
						'主卧床',
						'窗台板',
					],
					address: [],
				},
				{
					title: '次卧',
					childs: [
						'地板',
						'床头柜',
						'石膏线',
						'踢脚线',
						'衣柜',
						'开关插座',
						'次卧灯',
						'木门',
						'次卧床',
						'窗台板',
					],
					address: [],
				},
				{
					title: '厨房',
					childs: [
						'橱柜地柜',
						'燃气管',
						'调味篮',
						'水槽',
						'地砖',
						'集成吊灯',
						'双面门套',
						'热水器柜',
						'石英石包',
						'煤气管',
						'橱柜台面',
						'骑马抽',
						'碗碟篮',
						'水槽龙头',
						'墙砖',
						'集成灯',
						'过门石',
						'百叶门',
						'开关插座',
						'橱柜吊柜',
					],
					address: [],
				},
				{
					title: '卫生间',
					childs: [
						'木门',
						'地砖',
						'坐便器',
						'三角架',
						'花洒',
						'单杆毛巾架',
						'浴室柜',
						'马桶刷',
						'过门石',
						'集成吊灯',
						'淋浴屏',
						'风暖浴霸',
						'地漏',
						'开关插座',
						'纸巾架',
						'脸盆龙头',
					],
					address: [],
				},
				{
					title: '阳台',
					childs: [
						'地板',
						'洗衣机龙头',
						'灯具',
						'洗衣机地漏',
						'过门石',
						'单杆毛巾架',
						'浴室柜',
						'马桶刷',
						'过门石',
						'吸顶灯',
					],
					address: [],
				},
			],
			list2: [
				{
					desc: '安全防护 远离触电危险',
					title: '360°防护工艺',
				},
				{
					desc: '瓷砖黏贴效果更好，空间使用率更高',
					title: '空间1+1工艺',
				},
				{
					desc: '隔离楼上下水管水流声还你安静的空间',
					title: '橫无声工艺',
				},
				{
					desc: '卫生间会被湿气入侵房间干爽舒适',
					title: '门边泛水隔离工艺',
				},
				{
					desc: '不会影响网络等弱电信号信号满格的安全感',
					title: '抗干扰工艺',
				},
				{
					desc: '整屋墙面挂网格布防止墙面油漆开裂',
					title: '零开裂挂网工艺',
				},
				{
					desc: '卫生间安装双重防臭地漏给你的卫生间不一样',
					title: '双重防臭工艺',
				},
				{
					desc: '提高热效率节约能源更为你省钱',
					title: '热能保护工艺',
				},
				{
					desc: '墙边阳角采用护角条使墙角不会因磕碰而受损',
					title: '阳角零伤害工艺',
				},
				{
					desc: '高规格材料高规格安装工艺高规格成品确保',
					title: '顶面零开裂工艺',
				},
				{
					desc: '每一块地板都要五龙悬浮铺贴好脚感更要',
					title: '五龙悬浮竹炭包工艺',
				},
				{
					desc: '横竖十字交叉的防水工艺无缝防水盛水不漏',
					title: '盛水不漏工艺',
				},
				{
					desc: '回形切割+2倍坡度无死角迅速下水更舒心',
					title: '2*2极速下水',
				},
				{
					desc: '新建墙体拉墙筋为你打造安 全稳固的港湾',
					title: '双排连接工艺',
				},
				{
					desc: '浴室柜背部加插口式挂板45°加固承重柜',
					title: '45°加固保护',
				},
				{
					desc: '大圆弧排管抽拉灵活不伤线替换维修更便捷',
					title: '月亮弯工艺',
				},
				{
					desc: '黄墙绿地清爽干净全景实样 提前看家里',
					title: '1:1数字放样',
				},
				{
					desc: '玄关一键开关',
					desc2: '整屋智能享受',
					title: '智能一键开关',
				},
				{
					desc: '洗手台洗碗池3秒出热水暖流由指尖直达全',
					title: '3秒热水工艺',
				},
			],
			tabList: [
				{
					value: 1,
					icon: require('../image/price/box6_icon1.jpg'),
					url: require('../image/price/box6_img1.jpg'),
					title: '菲林格尔 ',
					form: '始创于1921年·德国',
					desc: '菲林格尔成立于德国巴登符腾堡州。九十余年来，菲林格尔逐渐发展为欧洲最大的旅行车内家具制造商，是梅赛德斯·奔驰等著名汽车公司车内木制品的供货商和著名家具中间产品供货商。',
				},
				{
					value: 2,
					icon: require('../image/price/box6_icon2.jpg'),
					url: require('../image/price/box6_img2.jpg'),
					title: '阿尔贝娜',
					form: 'Alpina品牌成立于1909年',
					desc: '专注于消费者涂料市场，品牌源自DAW SE第一代创始人在阿尔卑斯山度假时，被雪山白色的美景吸引，萌发了要研制出一款像雪山一样白的装饰涂料。',
				},
				{
					value: 3,
					icon: require('../image/price/box6_icon3.jpg'),
					url: require('../image/price/box6_img3.jpg'),
					title: '索菲亚',
					form: 'SOGAL源自法国1981年',
					desc: '索菲亚一直专注柜类定制家具，产品包含衣柜、书柜、电视柜等定制家具。索菲亚板材环保超越欧标，板材曾获美国环保署NAF环保认证和通过瑞士SGS机构严苛测定。',
				},
				{
					value: 4,
					icon: require('../image/price/box6_icon4.jpg'),
					url: require('../image/price/box6_img4.jpg'),
					title: '大金',
					form: '自1924年成立以来',
					desc: '始终致力于探索健康、舒适空气的奥秘。作为一家集空调、冷媒、压缩机的研发、生产、销售、售后服务位一体的世界知名企业。',
				},
				{
					value: 5,
					icon: require('../image/price/box6_icon5.jpg'),
					url: require('../image/price/box6_img5.jpg'),
					title: '慕思',
					form: 'DeRUCCI 品牌创立于2004年',
					desc: '致力于人体健康睡眠研究，专业从事整套健康睡眠系统的研发、生产和销售，在业内创新打造了“量身定制个人专属的健康睡眠系统”。',
				},
				{
					value: 6,
					icon: require('../image/price/box6_icon6.jpg'),
					url: require('../image/price/box6_img6.jpg'),
					title: '卫水宝',
					form: '世界领先的PP-R 管道产品制造商之一',
					desc: 'VESBO(卫水宝)公司是一家在热塑管工业领域一直处于领先地位的制造商。VESBO不仅为室内给排水提供了解决的方法，在垂直，加热,污水处理等领域中更是佼佼者。',
				},
			],
			curTab: 0,
		}
	},
	methods: {
		cahngeTabs(index) {
			this.curTab = index
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .whole {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
			.box1 {
                margin: 0 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: column;
				width:calc(100% - 24px) ;
				padding: 30px;
				box-sizing: border-box;
				margin-top: 50px;
				box-sizing: border-box;
				background:no-repeat  center/cover url(../image/decoration/whole_box1_bg.jpg);
				border-radius: 9px;
                .form-box{
                    width: 300px;
					height: 340px;
                }
				.img1 {
                    margin-top: 30px;
					width: 300px;
					height: 300px;
                    object-fit: cover;
				}
			}
			.box2 {
				margin-top: 50px;
				width: 100%;
				// height: 1300px;
				box-sizing: border-box;
				background: #fff;
				.header {
                    padding: 0 30px;
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
                        display: none;
					}
					.text3 {
                        display: none;
					}
                    .text4{
                        margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
                        text-align: center;
                    }
				}
                .box2-content{
                    display: none;
                }
				.box2-content1 {
                    margin-top: 20px;
					width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-flow: column;
					.box-left {
						width: 100%;
                        padding: 0 12px;
                        box-sizing: border-box;
                        flex-wrap: wrap;
						.list-item:last-child {
							.title {
								border-right: none !important;
							}
						}
                        .list-active{
                            background: #FD8B2F !important;
                        }
						.list-item {
							width: 100px;
                            height: 40px;
                            background: #753CBE;
                            border-radius: 2px;
                            margin-bottom: 14px;
							.title {
                                
                                font-weight: 500;
                                font-size: 21px;
                                color: #FFFFFF;
							}
							
						}
					}
                    .desc {
                        z-index: 9;
                        width: 347px;
                        height: 200px;
                        background: #ffffff;
                        box-shadow: 0px 16px 22px 0px rgba(0, 0, 0, 0.2);
                        border-radius: 4px;
                        border: 2px solid #fd8b2f;
                        position: relative;
                        .top {
                            width: 179px;
                            height: 38px;
                            background: #fd8b2f;
                            border-radius: 0 0 35px 35px;
                            .text3 {
                                
                                font-weight: bold;
                                font-size: 22px;
                                color: #ffffff;
                            }
                            .lin {
                                height: 1px;
                                width: 30px;
                                background: #ffffff;
                            }
                        }
                        .bottom {
                            width: 100%;
                            padding: 0 20px;
                            box-sizing: border-box;
                            margin-top: 10px;
                            display: grid;
                            grid-template-columns: 120px 110px 80px;
                            // grid-template-rows: repeat(7,1fr);
                            // grid-auto-flow: column;
                            .text1 {
                                text-align: left;
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #000000;
                                line-height: 20px;
                            }
                        }
                        .cus-line1 {
                            position: absolute;
                            top: 53px;
                            left: 110px;
                            background: #c8c8c8;
                            width: 1px;
                            height: 138px;
                        }
                        .cus-line2 {
                            position: absolute;
                            top: 53px;
                            right: 110px;
                            background: #c8c8c8;
                            width: 1px;
                            height: 138px;
                        }
                    }
					.box-right {
						width: 350px;
                        height: 280px;
						position: relative;

						.img3 {
							width: 100%;
							height: 100%;
						}
						.mask {
							position: absolute;
							width: 20px;
							height: 20px;
							background: #f5f1ed;
							border-radius: 50%;
							.mask2 {
								width: 15px;
								height: 15px;
								background: #ffffff;
								border-radius: 50%;
								.maks3 {
									width: 13px;
									height: 13px;
									background: #fd8b2f;
									border-radius: 50%;
								}
							}
						}
                        .address0{
                            left: 112px;
                            top: 157px;
                        }
                        .address1{
                            left: 83px;
                            top: 79px;
                        }
                        .address2{
                            left: 255px;
                            top: 200px;
                        }
                        .address3{
                            left: 166px;
                            top: 19px;
                        }
                        .address4{
                            left: 133px;
                            top: 48px;
                        }
                        .address5{
                            left: 47px;
                            top: 191px;
                        }
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 20px;
					.tab-list {
						width: 100%;
						padding: 0 15px;
						box-sizing: border-box;
						.list-item {
							width: 50px;
							height: 50px;
							background: #ffffff;
							box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
							border-radius: 50%;
							overflow: hidden;
							cursor: pointer;
							box-sizing: border-box;
							// border: 3px solid #7840BF;
							.img3 {
								width: 45px;
								height: 30px;
                                object-fit: cover;
							}
						}
						.tab-active {
							border: 1px solid #7840bf;
						}
					}
					.banner {
						margin-top: 20px;
						width: 100%;
						height: 300px;
						position: relative;
						.img4 {
							width: 100%;
							height: 100%;
                            object-fit: cover;
						}
						.info {
							position: absolute;
							top: 50px;
							right: 0;
							width: 250px;
							height: 200px;
							background: #ffffff;
							border-radius: 10px 0 0 10px;
							opacity: 0.98;
							padding: 10px;
							box-sizing: border-box;
							.text3 {
								
								font-weight: bold;
								font-size: 20px;
								color: #120523;
								line-height: 20px;
							}
							.text4 {
								
								font-weight: bold;
								font-size: 16px;
								color: #120523;
								line-height: 16px;
								margin-top: 10px;
							}
							.text5 {
								
								font-weight: 400;
								font-size: 13px;
								color: #444444;
								line-height: 30px;
								margin-top: 10px;
								line-height: 18px;
							}
						}
					}
				}
			}
			.box4 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
                    padding: 0 30px;
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
                        text-align: center;
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box4-content {
					width: 100%;
                    // height: 300px;
					margin-top: 16px;
					background:no-repeat center/cover url(../image/decoration/whole_box4_bg.jpg);
                    padding-bottom: 280px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    // background-position: right;
                    // background-size: 50% 100%;
                    // background-repeat: no-repeat;
					.list{
                        background: #fff;
                        border-radius: 0 0 40px 40px;
                        width: 100%;
                        height: 100%; 
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 0 17px;
                        box-sizing: border-box;
                        margin-top: -3px;
                        .list-item{
                            width: 106px;
                            margin-bottom: 17px;
                            border-radius: 5px;
                            overflow: hidden;
                            .img{
                                width: 100%;
                                height: 80px;
                                border-radius: 10px;
                                object-fit: cover;
                            }
                            .text3{
                                
                                font-weight: bold;
                                font-size: 14px;
                                color: #120523;
                                margin-top: 8px;
                            }
                        }
                    }
				}
			}
            .box5{
                margin-top: 152px;
                width: 100%;
                .pc-box{
                    display: none;
                }
                .mob-box{
                    width: 100%;
                    .top-box{
                        background:no-repeat center/cover   url(../image/build/innovate_box2_bg2.png);
                        padding:0 25px;
                        width: 100%;
                        height: 306px;
                        box-sizing: border-box;
                        .form{
                            margin-top: -266px;
                        }
                    }
                    .bottom{
                        margin-top: 50px;
                        width: 100%;
                        // height: 400px;
                        background: #FFFFFF;
                        .header{
                            width: 100%;
                            padding: 0px 25px;
                            box-sizing: border-box;
                            .text1{
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #120523;
                                
                            }
                            .text2{
                                margin-top: 10px;
                                text-align: center;
                                
                                font-weight: 400;
                                font-size: 12px;
                                color: #444444;
                            }
                        }
                        .list{
                            width: 100%;
                            margin-top: 26px;
                            .list-item{
                                box-sizing: border-box;
                                height: 42px;
                                background: #753CBE;
                                border-radius: 21px;
                                padding: 0 20px;
                                margin-bottom: 13px;
                                border-radius:  20px 0 0 20px;
                                .title{
                                    
                                    font-weight: bold;
                                    font-size: 17px;
                                    color: #EDEDED;
                                    line-height: 17px;
                                }
                                .line{
                                    margin: 0 10px;
                                    width: 1px;
                                    height: 14px;
                                    background: #EDEDED;
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #EDEDED;
                                    line-height: 17px;
                                }
                            }
                            .item1{
                                width: 287px;
                            }
                            .item2{
                                width: 302px;
                            }
                            .item3{
                                width: 317px;
                            }
                            .item4{
                                width: 332px;
                            }
                            .item5{
                                width: 347px;
                            }
                            .item6{
                                width: 362px;
                            }
                        }
                        .img-box{
                            margin-top: 5px;
                            width: 100%;
                            height: 274px;
                            background:center/cover url(../image/decoration/whole_box5_bg2.jpg);
                            .btn{
                                width: 138px;
                                height: 48px;
                                background: #753CBE;
                                border-radius: 24px;
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #FFFFFF;
                                line-height: 48px;
                                text-align: center;
                            }
                        }
                    }
                }
                
            }
		}
    }
}
@media screen and (min-width: 900px) {
	.whole {
		.box {
			width: 100%;
			height: 680px;
			// margin-top: 96px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
        .box-mob{
            display: none;
        }
		.content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
			.box1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
				width: 1556px;
				padding: 67px 180px;
				box-sizing: border-box;
				margin-top: 130px;
				background: url(../image/decoration/whole_box1_bg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				border-radius: 18px;
                .form-box{
                    width: 540px;
					height: 640px;
                }
				.form {
					width: 540px;
					height: 640px;
					background: #ffffff;
					border-radius: 20px;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					overflow: hidden;
					padding: 30px;
					box-sizing: border-box;
					.title {
                        
                        font-weight: bold;
                        font-size: 44px;
                        color: #18062F;
                        line-height: 30px;
					}
					.desc {
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                        line-height: 30px;
                        margin-top: 12px;
					}
					.form-box {
						margin-top: 40px;
						width: 100%;
						.phone {
							/deep/.el-input__inner {
								padding-left: 15px !important;
							}
						}
						.input {
							width: 100%;
							margin-bottom: 17px;
							.req {
								color: #e30c0c;
								height: 90px;
								line-height: 75px;
							}
							/deep/ .el-input {
								width: 100%;
								height: 90px;

								.el-input__inner {
									width: 100%;
									height: 90px;
								}
							}
                            
							/deep/ .el-input__inner,/deep/ .el-input__inner::placeholder {
								width: 100%;
								height: 90px;

                                
                                font-weight: 300;
                                font-size: 30px !important;
                                color: #444444 !important;
                                line-height: 90px !important;
							}

							/deep/.el-input__suffix {
								right: 20px;
								.m2 {
                                    
                                    font-weight: 300;
                                    color: #666666;
                                    line-height: 30px;
									height: 90px;
									line-height: 90px;
									font-size: 24px;
								}
							}
							/deep/.el-icon-arrow-down {
								font-size: 24px !important;
							}
						}
						.el-cascader {
							/deep/.el-input__suffix {
								right: 27px !important;
							}
						}
					}
					.btn {
						width: 480px;
						height: 90px;
						background: #fd8b2f;
						border-radius: 4px;
						
						font-weight: bold;
						font-size: 30px;
						color: #ffffff;
						line-height: 30px;
						cursor: pointer;
                        margin-top: 12px;
					}
				}
				.img1 {
					width: 550px;
					height: 550px;
				}
			}
			.box2 {
				margin-top: 130px;
				padding: 0 40px 0 180px;
				width: 100%;
				// height: 1300px;
				box-sizing: border-box;
				background: #fff;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
                    .text4{
                        display: none;
                    }
				}
                .box2-content1{
                    display: none;
                }
				.box2-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 37px;
					width: 100%;
					.box-left {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-flow: column;
						width: 560px;
						height: 895px;
						background: #753cbe;
						box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
						border-radius: 8px;
						.list-item:last-child {
							.title {
								border-bottom: none !important;
							}
						}
						.list-item {
							width: 100%;
							.title {
								height: 100px;
								line-height: 100px;
								width: 100%;
								text-align: center;

								border-bottom: 1px solid #8f61ca;
								
								font-weight: 500;
								font-size: 48px;
								color: #ffffff;
							}
							.desc {
								z-index: 9;
								margin-left: -42px;
								width: 644px;
								height: 394px;
								background: #ffffff;
								box-shadow: 0px 16px 22px 0px rgba(0, 0, 0, 0.2);
								border-radius: 8px;
								border: 4px solid #fd8b2f;
								position: relative;
								.top {
									width: 358px;
									height: 76px;
									background: #fd8b2f;
									border-radius: 0 0 70px 70px;
									.text3 {
										
										font-weight: bold;
										font-size: 44px;
										color: #ffffff;
									}
									.lin {
										height: 2px;
										width: 60px;
										background: #ffffff;
									}
								}
								.bottom {
                                    position: relative;
									width: 100%;
									padding: 0 40px;
									box-sizing: border-box;
									margin-top: 26px;
									display: grid;
                                    // overflow: hidden;
									grid-template-columns: 240px 230px 120px;
									// grid-template-rows: repeat(7,1fr);
									// grid-auto-flow: column;
									.text1 {
										text-align: left;
										
										font-weight: 400;
										font-size: 24px;
										color: #000000;
										line-height: 40px;
									}
								}
								.cus-line1 {
									position: absolute;
									top: 0px;
									left: 200px;
									background: #c8c8c8;
									width: 1px;
									height: 280px;
								}
								.cus-line2 {
									position: absolute;
									top: 0px;
									right: 200px;
									background: #c8c8c8;
									width: 1px;
									height: 280px;
								}
							}
						}
					}
					.box-right {
						flex: 1;
						height: 895px;
						position: relative;

						.img3 {
							width: 100%;
							height: 100%;
						}
						.mask {
							position: absolute;
							width: 47px;
							height: 47px;
							background: #f5f1ed;
							border-radius: 50%;
							.mask2 {
								width: 33px;
								height: 33px;
								background: #ffffff;
								border-radius: 50%;
								.maks3 {
									width: 26px;
									height: 26px;
									background: #fd8b2f;
									border-radius: 50%;
								}
							}
						}
                        .address0{
                            left: 402px;
                            top: 520px;
                        }
                        .address1{
                            left: 240px;
                            top: 260px;
                        }
                        .address2{
                            left: 845px;
                            top: 620px;
                        }
                        .address3{
                            left: 550px;
                            top: 70px;
                        }
                        .address4{
                            left: 441px;
                            top: 180px;
                        }
                        .address5{
                            left: 129px;
                            top: 600px;
                        }
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 40px;
					.tab-list {
						width: 100%;
						padding: 0 290px;
						box-sizing: border-box;
						.list-item {
							width: 171px;
							height: 171px;
							background: #ffffff;
							box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
							border-radius: 50%;
							overflow: hidden;
							cursor: pointer;
							box-sizing: border-box;
							// border: 3px solid #7840BF;
							.img3 {
								width: 120px;
								height: 90px;
							}
						}
						.tab-active {
							border: 3px solid #7840bf;
						}
					}
					.banner {
						margin-top: 36px;
						width: 100%;
						height: 1100px;
						position: relative;
						.img4 {
							width: 100%;
							height: 100%;
						}
						.info {
							position: absolute;
							top: 245px;
							right: 220px;
							width: 760px;
							height: 610px;
							background: #ffffff;
							border-radius: 19px;
							opacity: 0.98;
							padding: 90px;
							box-sizing: border-box;
							.text3 {
								
								font-weight: bold;
								font-size: 64px;
								color: #120523;
								line-height: 64px;
							}
							.text4 {
								
								font-weight: bold;
								font-size: 48px;
								color: #120523;
								line-height: 55px;
								margin-top: 33px;
							}
							.text5 {
								
								font-weight: 400;
								font-size: 30px;
								color: #444444;
								line-height: 30px;
								margin-top: 39px;
								line-height: 48px;
							}
						}
					}
				}
			}
			.box4 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box4-content {
					width: 100%;
                    height: 1050px;
					margin-top: 50px;
					background: url(../image/decoration/whole_box4_bg.jpg);
                    background-position: right;
                    background-size: 50% 100%;
                    background-repeat: no-repeat;
                    display:flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: flex-start;
					.list{
                        background: #fff;
                        border-radius: 0 80px 80px 0;
                        width: 1120px;
                        height: 100%; 
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 50px 80px 0 80px;
                        box-sizing: border-box;
                        .list-item{
                            width: 300px;
                            margin-bottom: 50px;
                            border-radius: 10px;
                            overflow: hidden;
                            .img{
                                width: 100%;
                                height: 230px;
                                border-radius: 10px;
                            }
                            .text3{
                                
                                font-weight: bold;
                                font-size: 32px;
                                color: #120523;
                                margin-top: 15px;
                            }
                        }
                    }
				}
			}
            .box5{
                margin-top: 230px;
                height: 1300px;
                width: 100%;
                .mob-box{
                    display: none;
                }
                .pc-box{
                    box-sizing: border-box;
                    background: url(../image/build/innovate_box2_bg2.png);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding:0 180px;
                    width: 100%;
                    height: 100%;
                    .top-box{
                        // width: 100%;
                        margin-top: -110px;
                    }
                    .bottom{
                        margin-top: 47px;
                        width: 1556px;
                        height: 1000px;
                        background: #FFFFFF;
                        border-radius: 18px;
                        padding: 68px 130px;
                        box-sizing: border-box;
                        .header{
                            .text1{
                                
                                font-weight: bold;
                                font-size: 72px;
                                color: #120523;
                            }
                            .text2{
                                margin-top: 25px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #444444;
                            }
                        }
                        .img-box{
                            margin-top: 60px;
                            height: 650px;
                            width: 100%;
                            .img{
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
               
                
                .box-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 50px;
                    .item{
                        width: 270px;
                        height: 120px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        margin-bottom: 20px;
                    }
                }
            }
		}
	}
}
</style>
